body{
  margin:0;
  padding:0;
  font: 18px/1.2em Roboto;
  background-color: #252525;
  color: #aaa;
}



a{
  color:#6ab3fc;
  text-decoration: none;
  font-weight: bold;
}

.Navlink{
  text-decoration: none;
}





.MuiTypography-code {
  font-family: "Courier New", monospace;
  background-color: #f0f0f0;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}



.spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




.nopadding-td td{
  padding:3px 10px !important;
}

.ds{
  color:#dbd8d8;
}

.loader-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    opacity: 0;
    animation: fadeIn .5s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Начальная непрозрачность при старте анимации */
  }
  to {
    opacity: 1; /* Конечная непрозрачность после завершения анимации */
  }
}



.logs{
  background-color: #333;
  padding: 10px 20px;
  font: 13px/1.25em Consolas,Courier !important;
  color:#f0f0f0;
  border-radius: 5px;
}

.logs li{
  list-style: none;
}

.colorGreen{
  color: rgb(90, 247, 90);
}
.colorYellow{
  color: rgb(238, 247, 118);
}

.server {
  width:50%;
  padding: 20px;
  background: linear-gradient(-45deg, #f0f0f0 25%, #eaeaea 25%, #eaeaea 50%, #f0f0f0 50%, #f0f0f0 75%, #eaeaea 75%, #eaeaea 100%);
  background-size: 30px 30px; /* Измените размер полос по вашему вкусу */

  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  color: #333;
}



@keyframes moveStripes {
  to {
    background-position: 100% 0;
  }
}

.hide{
  display: none;
}

.server.blocked{
  color: red;
  z-index: 1000;
}

.loader-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  opacity: 0;
  animation: fadeIn .5s ease-in forwards;
}

.text-serverg{
  background-color: #eee !important;
}

.server-connect{
  margin-top: 20px;
  font: 11px/1.1em Verdana;
}

.table_style{
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
}

.table_style tr:hover{
  background-color: #333;
}

.table_style td{
  border: 1px solid #373838;
  margin: 0;
  padding: 2px 5px;
  font: 13px/1.1em Consolas;
}

.table_style th{
  text-align: left;
}

.table_style thead td{
  font-weight: bold;
}


.ins{
  background-color:rgb(238, 247, 118);
  color:#333
}

.btnFirmware, .btnDelete{
  font-size: 18px !important;
  color: #1668b9;
  margin-right: 15px;
}


.btnDeleteSm:hover, .btnDelete:hover, .btnFirmware:hover, .btnReloadecm:hover{
  color: #ce0f01;
  cursor: pointer;
}

.freq-busy{
  background-color: #640d0d;
}

.color-gray{
  color: gray !important;
}


.tipa-a{
  color: #6ab3fc;
  cursor: pointer;
  padding:0 4px;
}

.tipa-a:hover{
  color: #ff4949;
  text-decoration: underline;
}

.tipa-a.sm{
  font-size: 16px;
}

.select-mask{
  font-size:13px;
  margin-top:10px;
}

.select-mask li{
  list-style-type: none;
  display: inline-block;
  border:1px solid #9bbde2;
  border-radius:2px;
  padding: 2px 5px;
  cursor:pointer;
  margin: 0 5px 5px 0;
}

.select-mask li:hover{
  border:1px solid #0d63be;
  color:white;
  background-color: #0d63be;
}

.btnDelete:active, .btnFirmware:active{
  background-color: #ce0f01;
  color: #fff;
  cursor: pointer;
}

.btnDeleteSm{
  position: relative;
  top:7px;
  padding-left:20px;
}

.btnReloadecm{
  font-size: 16px !important;
  color: #1668b9;
  margin-left: 15px;
}

@keyframes highlightAnimation {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #cbf8cb;
  }
  100% {
    background-color: transparent;
  }
}

.highlighted-row {
  animation: highlightAnimation .5s ease;
}

.mode{
  background-color: rgb(107, 107, 107);
  padding: 3px;
  border-radius: 3px;
  position: relative;
  bottom:3px;
  color: white;
  margin-right: 10px;
}

.pagenation{
  cursor: pointer;
  color: #1668b9;
  border-radius: 15px;
  border:1px solid #dbdada;
}

.pagenation:hover{
  color: #050505;
  border:1px solid #9b9a9a;
}



.future-sub{
  background-color: #cbf8cb;
}

.last-sub{
  background-color: #fad5d5;
}

.eraser-off .btnDelete{
  color: rgb(207, 207, 207) !important;
}





.link-clients-package{
  color:#026bdb
}
.link-clients-package:hover{
  font-weight:bold;
  cursor:pointer;
}






/* DATE PICKER */
.react-datepicker-wrapper {
  margin-top: 20px;
  display: inline-block;
  width:100%;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;

}

.react-datepicker__input-container input {
  padding: 10px 0;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  text-align: center;
  width:100%;
}

.react-datepicker__input-container input:focus {
  border-color: #007bff;
}

.react-datepicker-wrapper .react-datepicker__input-container::after {
  content: "\1F4C6";
  font-size: 18px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}



.snacksm-btn{
  margin-right: 20px !important;
}